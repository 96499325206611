import {graphql, navigate} from 'gatsby'
import React, {useEffect} from 'react'

import useTrackInterviewInteraction from 'src/hooks/useInterview'
import useViewer from 'src/hooks/useViewer'
import {AddUserRelativResponse, addUserRelativ} from 'src/lib/util'

export const query = graphql`
  query InterviewButtonLink {
    allContentfulInterviewCompletionPage {
      nodes {
        interviewButtonLink
      }
    }
  }
`

type InterviewData = {
  allContentfulInterviewCompletionPage: {
    nodes: {
      interviewButtonLink: string
    }[]
  }
}

const InterviewCoach = ({data}: {data: InterviewData}) => {
  const {interviewButtonLink} =
    data.allContentfulInterviewCompletionPage.nodes[0]
  const {viewer} = useViewer()
  const trackInterview = useTrackInterviewInteraction()

  useEffect(() => {
     
    if (viewer === 'not-logged-in') {
      navigate('/log-in/?redirect_from=interviewcoach', {
        state: {returnTo: '/interviewcoach'},
      })
    } else {
      const email = viewer?.student.email
      const name = viewer?.student.name

      if (email) {
        addUserRelativ(name, email)
          .then((response: AddUserRelativResponse) => {
            if (response.status === 'success') {
              console.log('Authentication successful')
              trackInterview('start')
              const languageInput = document.querySelector(
                '.language__input:checked'
              ) as HTMLInputElement| null

              const selectedLanguage = languageInput?.value || 'No language selected'
              console.log('Selected Language:', selectedLanguage)

              const url = new URL(interviewButtonLink)
              url.searchParams.append('email', response.email || '')
              url.searchParams.append('language', selectedLanguage  || '')
              navigate(url.toString())
            } else if (response.status === 'fail') {
              console.log('Authentication failed')
            } else {
              console.error('Unknown status:', response.status)
            }
          })
          .catch((error) => {
            console.error('Error:', error)
          })
      }
    }
  }, [viewer])

  return <></>
}

export default InterviewCoach
